import * as React from "react";

import Layout from "../components/Layout/Layout";
import AllNews from "../components/news/AllNews";

const news = () => {
  return (
    <Layout>
      <AllNews />
    </Layout>
  );
};

export default news;
