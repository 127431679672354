import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import ArrowRight from "../../images/arrow_right.svg";
import NewsItem from "../../components/index/NewsItem";
import News2 from "../../images/news2.webp";
// import { newsObjects } from "../../utils/newsObjects";
import Pagination from "../Pagination";
import { FC, useState } from "react";
import { GET_NEWS } from "../../query/getNews";
import { useQuery } from "@apollo/client";
import { Link, useStaticQuery, graphql } from "gatsby";
// @ts-ignore

const AllNews = ({ intl }) => {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const { data, loading } = useQuery(GET_NEWS, {
    variables: {
      locale: intl.locale,
      pagination: { page, pageSize },
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className="grid xs:justify-center  sm:pl-0">
      <div className="flex flex-col grid-rows-2 justify-start  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
        <div className="ml-[0.2rem] mt-[2.5rem] mb-[2rem] "></div>
        <div className=" grid items-start h-[2.5rem]  justify-between mb-4  ">
          <h2 className="text-[2rem] font-semibold leading-5">
            {parse(intl.formatMessage({ id: "NewsTumar.NewsHeader" }))}
          </h2>
        </div>
        <div className="sm:items-baseline xs:items-center">
          <div className=" grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 items-start gap-y-28 gap-x-9 max-md:gap-y-9 max-sm:9">
            {data?.blogs?.data?.map((news: any) => (
              <Link
                to={`/news-detail/?id=${news.attributes.sharedId}`}
                key={news.id}
              >
                <NewsItem
                  intl={intl}
                  date={new Date(news.attributes.publishedAt)
                    .toLocaleDateString("en-GB")
                    .replace(/\//g, ".")}
                  title={news.attributes.title}
                  description={news.attributes.description}
                  img={news.attributes?.image?.data?.attributes?.url}
                />
              </Link>
            ))}
          </div>
          <Pagination
            setPage={setPage}
            pagination={data?.blogs?.meta?.pagination || {}}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AllNews);
