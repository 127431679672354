import React, { FC } from "react";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import Lipid from "../../images/lipid.webp";
import { cmsImageLoader } from "../../utils/cmsImageLoader";
import News2 from "../../images/news2.svg";
// import { cmsImageLoader } from "@/utils/cmsImageLoader";

type NewsItemProps = {
  intl: any;
  date: string;
  title: string;
  description: string;
  img: string;
};

// @ts-ignore
const NewsItem: FC<NewsItemProps> = ({
  intl,
  date,
  title,
  description,
  img,
}) => (
  <div className="grid xl:w-[17rem] lg:w-[19rem] sm:w-[20rem] sm:h-[21rem] xs:w-[18rem] xs:h-[auto]  border border-[#618EE4]-50  rounded-[1rem] ">
    {img ? (
      <img
        src={cmsImageLoader({
          src: img,
          width: 0,
        })}
        alt="news"
        className="xl:w-auto lg-w[19rem] sm:w-[20rem] "
        style={{ height: "180px", width: "100%" }}
      />
    ) : (
      <img
        src={News2}
        alt="placeholder"
        className="xl:w-auto lg-w[19rem] sm:w-[20rem]"
        style={{ height: "180px" }}
      />
    )}
    {/* <Image src={img} alt="" className="xl:w-auto lg-w[19rem] sm:w-[20rem]" /> */}
    <p className="font-regular sm:text-[0.875rem] xs:text-[0.75rem] pl-[1rem] pt-[1rem]">
      {date}
    </p>
    <div className="sm:px-[1rem] xs:pl-[1rem] xs:pr-[0.6rem] overflow-hidden">
      <p className="font-semibold sm:text-[1rem] xs:text-[0.875rem] pt-[0.625rem]">
        {title}
      </p>
      <p className="font-regular sm:text-[0.875rem] xs:text-[0.75rem pt-[0.625rem] pb-[1rem]">
        {description}
      </p>
    </div>
  </div>
);

export default injectIntl(NewsItem);
